export default function () {
  setTimeout(() => {
    const getAllPrimaryBtnsSkarabee = document.querySelectorAll('.btn-outline-primary');
    if (getAllPrimaryBtnsSkarabee) {
      getAllPrimaryBtnsSkarabee.forEach((e) => {
        e.style = 'padding: 19px 30px 15px !important;';
      });
    }

    const getSkarabeeContactForm = document.getElementById('skarabee-contact-form');
    if (getSkarabeeContactForm) {
      const getAllFormControlContact = getSkarabeeContactForm.querySelectorAll('.form-control');

      if (getAllFormControlContact) {
        getAllFormControlContact.forEach((e) => {
          e.classList.remove('form-control');
        });
      }
    }

    const checkOptionals = () => {
      const getSkarabeeOptional = document.getElementsByTagName('p');
      if (getSkarabeeOptional) {
      // eslint-disable-next-line no-restricted-syntax
        for (const item of getSkarabeeOptional) {
          if (item.innerHTML === ' (Optioneel) ') {
            item.style = 'display: none !important;';
          }
        }
      }
    };

    checkOptionals();

    const getSkarabeeHMODHForm = document.getElementById('skarabee-searchprofile');
    if (getSkarabeeHMODHForm) {
      getSkarabeeHMODHForm.addEventListener('click', (e) => {
        checkOptionals();
      });
    }
  }, '1000');
}
