export default function () {
  let storyWidth;
  let currentNr;
  const previousArrows = document.querySelectorAll('.story__previous');
  const nextArrows = document.querySelectorAll('.story__next');
  const oneStory = document.querySelector('.story');
  const allStories = document.querySelectorAll('.story');
  const storiesContainer = document.querySelector('.timeline__stories');
  const timelineItems = document.querySelectorAll('.timeline__item');
  const activeLine = document.querySelector('.active-line');

  const setActiveLineWidth = (nr) => {
    if (activeLine) {
      // divide timeline into parts (based on number of items)
      // set width of active line to reach the clicked item
      const activeWidth = (100 / (timelineItems.length - 1)) * (nr - 1);
      activeLine.style.width = `${activeWidth}%`;
    }
  };

  const setActiveLineItem = (nr) => {
    if (timelineItems) {
      const clickedItem = timelineItems[nr];

      // give clicked item and all preceding items the active class
      for (let i = 0; i < timelineItems.length; i += 1) {
        if (
          timelineItems[i] !== clickedItem &&
          !timelineItems[i].classList.contains('step__item--active')
        ) {
          timelineItems[i].classList.add('timeline__item--active');
        }
        if (i > nr - 1) {
          timelineItems[i].classList.remove('timeline__item--active');
        }
      }
    }
  };

  const setActiveStory = (nr) => {
    // get width of one story
    storyWidth = oneStory.offsetWidth;

    // determine difference in distance between current story and story to navigate to
    // then scroll that distance so the correct story is visible
    const nrDifference = nr - currentNr;
    storiesContainer.scrollLeft += storyWidth * nrDifference;

    // set active class on active story
    const previousActiveStory = document.querySelector('.story--active');
    previousActiveStory.classList.remove('story--active');
    for (let i = 0; i < allStories.length; i += 1) {
      if (i === nr - 1) {
        allStories[i].classList.add('story--active');
      }
    }

    // update current index
    currentNr = nr;
  };

  const handleClickPrevious = () => {
    if (currentNr > 1) {
      const previousIndex = currentNr - 1;
      setActiveStory(previousIndex);
      setActiveLineItem(previousIndex);
      setActiveLineWidth(previousIndex);
    }
  };

  const handleClickNext = () => {
    if (currentNr <= timelineItems.length) {
      const nextIndex = currentNr + 1;
      setActiveStory(nextIndex);
      setActiveLineItem(nextIndex);
      setActiveLineWidth(nextIndex);
    }
  };

  const handleClickTimelineItem = (e) => {
    // get index of clicked item
    const itemIndex = parseInt(e.currentTarget.dataset.index, 10);

    setActiveStory(itemIndex);
    setActiveLineItem(itemIndex);
    setActiveLineWidth(itemIndex);
  };

  const init = () => {
    if (storiesContainer) {
      currentNr = 1;
      storyWidth = oneStory.offsetWidth;

      // add eventlistener to each timeline item
      timelineItems.forEach((item) => {
        item.addEventListener('click', handleClickTimelineItem);
      });

      // add eventlistener to navigation arrows
      previousArrows.forEach((arrow) => {
        arrow.addEventListener('click', handleClickPrevious);
      });
      nextArrows.forEach((arrow) => {
        arrow.addEventListener('click', handleClickNext);
      });
    }
  };

  init();
}
