export default function () {
  const pageheader = document.querySelector('.page-header');
  const mainNavWrap = document.querySelectorAll('.main-nav__wrap');
  const scrollMenu = document.querySelector('.scroll-menu');
  const navTrigger = document.querySelector('.main-nav-trigger');

  if (window.pageYOffset > 60) {
    pageheader.classList.add('scrolled');
  }

  window.addEventListener('scroll', () => {
    if (window.pageYOffset > 120) {
      pageheader.classList.add('scrolled');
      pageheader.classList.remove('open');
      mainNavWrap.forEach(wrap => wrap.classList.remove('open'));
      scrollMenu.classList.remove('disappear');
    } else {
      pageheader.classList.remove('scrolled');
    }
  });

  if (window.innerWidth > 768) {
    navTrigger.addEventListener('mouseenter', (event) => {
      event.preventDefault();

      pageheader.classList.add('open');
      mainNavWrap.forEach(wrap => wrap.classList.add('open'));
      scrollMenu.classList.add('disappear');
    });

    pageheader.addEventListener('mouseleave', (event) => {
      event.preventDefault();

      if (document.querySelector('.page-header.open')) {
        pageheader.classList.remove('open');
        mainNavWrap.forEach(wrap => wrap.classList.remove('open'));
        scrollMenu.classList.remove('disappear');
      }
    });
  }
}
