export default function () {
  const rawText = document.querySelector('.split-text').innerHTML;
  const totalCharacters = rawText.length;

  if (totalCharacters > 0) {
    const sentences = rawText.replace(/([.?!])\s*(?=[A-Z])/g, '$1|').split('|');
    const sentencesAmount = Math.floor(sentences.length / (totalCharacters / 700));

    const rest = sentences.length % sentencesAmount > 0 ? 1 : 0;
    const totalGrids = Math.floor(sentences.length / sentencesAmount) + rest;

    const tempArray = [];
    for (let i = 0; i < sentences.length; i += sentences.length / totalGrids) {
      tempArray.push(sentences.slice(i, i + sentences.length / totalGrids));
    }

    tempArray.forEach((element, index) => {
      let substring = '';
      element.forEach((subArray) => {
        substring += `${subArray} `;
      });

      const clone = document.querySelector('.flip-grid').cloneNode(true);
      clone.id = index;
      const sliderIndex = index + 3;
      document.querySelector('.flip-grid__wrapper').appendChild(clone);

      const fancyboxSlider = document.querySelectorAll('.fancybox__slider .fancybox');

      const flipGridImageWrapper = [...document.querySelectorAll('.flip-grid')].find(
        (grid) => grid.id === `${index}`,
      );
      const flipGridImage = flipGridImageWrapper.getElementsByTagName('img')[0];
      const flipGridText = flipGridImageWrapper.querySelector('.flip-grid__text');
      const flipGridTextPtag = flipGridText.getElementsByTagName('p')[0];

      if (fancyboxSlider[sliderIndex]) {
        const flipGridSource = flipGridImageWrapper.querySelector('source');
        flipGridSource.srcset = fancyboxSlider[sliderIndex].children[1].children[0].srcset;
        flipGridImage.src = fancyboxSlider[sliderIndex].children[0].src;
        flipGridImage.parentElement.setAttribute('data-gallery-item', '');
        fancyboxSlider[sliderIndex].removeAttribute('data-gallery-item');
      } else if (fancyboxSlider[index] !== undefined) {
        flipGridImage.src = fancyboxSlider[index].href;
      }

      if (fancyboxSlider[index] !== undefined) {
        flipGridTextPtag.innerHTML = substring;
      } else {
        const previousFlipGridImageWrapper = [...document.querySelectorAll('.flip-grid')].find(
          (grid) => grid.id === `${index - 1}`,
        );
        if (previousFlipGridImageWrapper) {
          const previousFlipGridText =
            previousFlipGridImageWrapper.querySelector('.flip-grid__text');
          const previousFlipGridTextPtag = previousFlipGridText.getElementsByTagName('p')[0];

          previousFlipGridTextPtag.innerHTML += substring;
        }
      }
    });
  }
}
