import Tab from './Tab';

export default class Tabs {
  constructor(itemElementQuery = '.js-tabs-item') {
    this.itemElementQuery = itemElementQuery;
    this.items = [];
    this.registerItems();
  }

  registerItems() {
    const items = document.querySelectorAll(this.itemElementQuery);
    this.items = [...items].map((el) => new Tab(el));
  }
}
