export default class ReferenceIndex {
  constructor() {
    const selectInput = document.querySelector('.js-choice');

    selectInput.addEventListener('click', () => {
      let url = '';
      const values = selectInput.value;
      const routeUrl = document.querySelector('.route-url').textContent;

      for (let i = 0; i < values.length; i++) {
        url += (i > 0 ? '+' : '') + values[i];
      }

      if (url !== '') {
        window.location.href = `${routeUrl}/${url}`;
      } else {
        window.location.href = routeUrl;
      }
    });
  }
}
