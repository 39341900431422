export default function () {
  const getAllSkarabeeForms = document.querySelectorAll('[id^="skarabee-"]');

  if (getAllSkarabeeForms) {
    getAllSkarabeeForms.forEach((form) => {
      let skarabeeInputs = [];

      const loopInputs = () => {
        skarabeeInputs.forEach((input) => {
          const label = document.createElement('label');
          const span = document.createElement('span');
          span.classList.add('label__text');
          label.classList.add('label');

          if (input.placeholder) {
            span.textContent = input.placeholder;
            label.htmlFor = input.placeholder;
            input.id = input.placeholder;
          }

          label.appendChild(span);
          input.parentElement.insertBefore(label, input.nextSibling);
          input.parentElement.classList.add('floating-label');
        });
      };

      const checkSkarabeeInputs = () => {
        skarabeeInputs = form.querySelectorAll('[type="text"]');

        if (skarabeeInputs.length > 0) {
          loopInputs();
          return;
        }

        setTimeout(checkSkarabeeInputs, 100);
      };

      checkSkarabeeInputs();
    });
  }
}
