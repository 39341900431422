/* eslint-disable no-shadow */
import Masonry from 'masonry-layout/dist/masonry.pkgd';

export default function () {
  const grid = document.querySelector('.masonry-grid');
  const aanbodGrid = document.querySelectorAll('.aanbod-grid');

  document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
      if (grid) {
        new Masonry(grid, {
          itemSelector: '.grid-item',
          columnWidth: '.grid-sizer',
          percentPosition: true,
          gutter: '.gutter-sizer',
          stagger: 30,
        });

        setTimeout(() => {
          grid.classList.add('visible');
        }, 100);
      }

      if (aanbodGrid) {
        for (let i = 0; i < aanbodGrid.length; i++) {
          new Masonry(aanbodGrid[i], {
            itemSelector: '.grid__item',
            columnWidth: '.grid-sizer',
            percentPosition: true,
            gutter: '.gutter-sizer',
            horizontalOrder: true,
          });

          setTimeout(() => {
            for (let i = 0; i < aanbodGrid.length; i++) {
              aanbodGrid[i].classList.add('visible');
            }
          }, 100);
        }
      }
    }, 100);
  });
}
