export default function () {
  if (!window.location.href) {
    return;
  }

  const queryParams = window.location.href.split('?');
  const clearButton = document.querySelector('.js-city-filter__clear');
  const editButton = document.querySelector('.js-city-edit');

  if (queryParams[1] && queryParams[1] !== '#map-modal' && queryParams[1].includes('zip%5B%5D=')) {
    clearButton.classList.remove('vh');
    editButton.classList.remove('vh');
  } else if (clearButton && editButton) {
    clearButton.classList.add('vh');
    editButton.classList.add('vh');
  }
}
