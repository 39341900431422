export default function () {
  // Disable click on all images
  const allImages = document.querySelectorAll('img');
  if (allImages) {
    allImages.forEach((v) => {
      v.setAttribute('draggable', false);

      v.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
    });
  }
}
