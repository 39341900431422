import { readCookie, createCookie } from '../cookies';

export default function () {
  const modalContainer = document.querySelector('.js-unleash-modal');
  if (!modalContainer) {
    return;
  }

  const modalID = modalContainer.getAttribute('data-title');
  const modal = window.modalManager.idModalMap.get(modalID);

  if (!modal) {
    return;
  }

  const cookieName = `huysewinkel-popup-${modalID}`;
  const home = document.querySelector('.home');
  const shouldOpenModal = !readCookie(cookieName);

  if (home && shouldOpenModal) {
    setTimeout(() => {
      modal.show();
      createCookie(cookieName, 'popup', 2);
    }, 7000);
  } else if (shouldOpenModal) {
    modal.show();
    createCookie(cookieName, 'popup', 2);
  }
}
