export default class Tab {
  constructor(el) {
    this.el = el;
    this.contentEl = document.querySelector(`[data-tabs-content-id="${el.dataset.contentId}"]`);
    this.registerEventListeners();
  }

  registerEventListeners() {
    this.el.addEventListener('click', () => this.setActiveTab());
  }

  setActiveTab() {
    this.setInactiveTabs();
    this.contentEl.classList.add('active');
    this.el.classList.add('active');
  }

  setInactiveTabs() {
    const items = document.querySelectorAll('.js-tabs-item');
    const contentItems = document.querySelectorAll('.js-tabs-content-item');
    [...items, ...contentItems].forEach((el) => el.classList.remove('active'));
  }
}
