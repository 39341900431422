import { isMobile } from '../utils/isMobile';

export default class Team {
  constructor() {
    const navigationBreakpoint = isMobile ? 320 : window.innerHeight - 45;
    const logoFull = document.querySelector('#logoFull');
    const logoSmall = document.querySelector('#logoSmall');
    const pageHeader = document.querySelector('.page-header');

    window.addEventListener('scroll', () => {
      if (window.scrollY > navigationBreakpoint) {
        logoFull.style.display = 'none';
        logoSmall.style.display = 'block';
        pageHeader.classList.add('page-header--collapsed');
      } else {
        logoFull.style.display = 'block';
        logoSmall.style.display = 'none';
        pageHeader.classList.remove('page-header--collapsed');
      }
    });
  }
}
