export default class RecaptchaForm {
  constructor(formId) {
    this.form = document.querySelector(formId);

    if (this.form) {
      this.form.addEventListener('submit', (e) => {
        e.preventDefault();

        if (this.form.classList.contains('validate')) {
          if (this.form.checkValidity() === false) {
            return false;
          }
        }

        const $recaptchaResponse = this.form.querySelector('[name="g-recaptcha-response"]');
        if ($recaptchaResponse && $recaptchaResponse.value !== '') {
          // Use window.grecaptcha as recaptcha is async loaded
          window.grecaptcha.reset();
        }

        window.grecaptcha.execute(this.form.querySelector('.g-recaptcha').dataset.widgetid);

        return false;
      });
    }
  }

  submitCallback() {
    if (!this.form.classList.contains('form-ajax')) {
      this.form.removeEventListener('submit', this.submitCallback);
      this.form.submit();
      return;
    }

    const url = this.form.getAttribute('action');
    const data = new FormData(this.form);
    const buttons = this.form.querySelectorAll('input[type="submit"], button');

    fetch(url, {
      method: 'POST',
      body: data,
    })
      .then(response => response.json())
      .then((response) => {
        document.querySelectorAll('li').forEach((el) => {
          el.classList.remove('error');
        });

        if (response.errors === false) {
          this.form.innerHTML = response.result;

          // Trigger GA or GTM event
          if (this.form.dataset.origin) {
            const dataLayer = window.dataLayer || [];
            dataLayer.push({
              event: 'form_submission',
              category: this.form.dataset.origin,
              action: 'Informatie_aanvragen',
              label: 'volledig_aanbod',
            });
          }
        }

        buttons.forEach((el) => {
          el.removeAttribute('disabled');
        });
      })
      .catch((responseData) => {
        const response = responseData.responseJSON;
        // eslint-disable-next-line no-console
        console.log(`Error: ${responseData.responseText}`);
        this.form.querySelector('.result').classList.add('error');
        this.form.querySelector('.result').style.display = 'block';

        if (response) {
          this.form.querySelector('.result').innerHTML = response.result;
        } else {
          this.form.querySelector('.result').innerHTML = 'Er ging iets mis, probeer het later opnieuw.';
        }

        if (response.fields) {
          response.fields.forEach((field) => {
            document.querySelectorAll(`input[name="${field}"],textarea[name="${field}"]`).forEach((el) => {
              el.classList.add('error');
            });
          });
        }
      })
      .then(() => {
        buttons.forEach((el) => {
          el.setAttribute('disabled', false);
        });
      });
  }
}

// explicit recaptcha methode (zodat er meerdere op 1 pagina kunnen)
window.onloadReCaptchaCallback = () => {
  document.querySelectorAll('.g-recaptcha').forEach((el, i) => {
    const attributes = {
      sitekey: el.dataset.sitekey,
      size: el.dataset.size,
      callback: el.dataset.callback,
    };

    const widgetid = window.grecaptcha.render(el, attributes);
    el.dataset.widgetid = widgetid;
  });
};
