export default function () {
  const videos = document.querySelectorAll(
    `iframe:not(.js-plyr-iframe)[src*="youtube.com/embed"],
    iframe:not(.js-plyr-iframe)[src*="youtube-nocookie.com/embed"],
    iframe:not(.js-plyr-iframe)[src*="player.vimeo"]`,
  );

  [...videos].forEach((video) => {
    const container = document.createElement('div');
    container.classList.add('video-container');
    video.parentNode.insertBefore(container, video);
    container.appendChild(video);
  });
}
