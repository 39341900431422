import RecaptchaForm from '../components/forms/RecaptchaForm';
import Tabs from '../components/tabs/Tabs';

export default class Nieuwland {
  constructor() {
    const formNames = ['appointment', 'general', 'plans'];
    formNames.forEach((formName) => {
      const recaptchaForm = new RecaptchaForm(`#form-nieuwland-${formName}`);
      const ucfirstFormName = this.capitalizeFirstLetter(formName);
      window[`submitRecaptchaFormNieuwland${ucfirstFormName}`] = () => {
        recaptchaForm.submitCallback();
      };
    });

    new Tabs(); // eslint-disable-line
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
