import { Swiper, Navigation, A11y, Pagination, Thumbs, Autoplay } from 'swiper';

export default function () {
  Swiper.use([Navigation, A11y, Pagination, Thumbs, Autoplay]);

  const defaultConfig = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: 120,
    preloadImages: true,
    updateOnImagesReady: true,
    effect: 'fade',
    loop: false,
    freeMode: true,
    freeModeMomentumBounceRatio: 0.1,
    freeModeSticky: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2,
      },
    },
  };

  // Configuration overrides
  const configurations = new Map();

  configurations.set('rent-slider', {
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: 60,
    preloadImages: true,
    updateOnImagesReady: true,
    effect: 'fade',
    loop: false,
    freeMode: true,
    freeModeMomentumBounceRatio: 0.1,
    freeModeSticky: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  });

  configurations.set('valuation-marketing-slider', {
    direction: 'horizontal',
    slidesPerView: '1',
    spaceBetween: 40,
    preloadImages: true,
    updateOnImagesReady: true,
    loop: false,
    autoplay: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
    },
  });

  // Init sliders
  const sliders = [...document.querySelectorAll('.js-slider')];
  sliders.forEach((sliderEl) => {
    let config = configurations.get(sliderEl.dataset.config);
    if (!config) {
      config = defaultConfig;
    }
    window.slider = {
      ...window.slider,
      [sliderEl.dataset.config]: new Swiper(sliderEl.querySelector('.swiper-container'), config),
    };
  });
}
