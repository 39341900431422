import subcities from '../../../json/subcities.json';

export default function () {
  const generateOptionTags = (json, generateRegion) => {
    const plaatsnamen = json.Plaatsnamen;
    const activeRegions = new Set();

    return plaatsnamen.map((place) => {
      const { Postcode, Plaatsnaam, Hoofdgemeente } = place;
      const isSelected = window.location.href.includes(Postcode);
      const selectedAttribute = isSelected ? 'selected' : '';

      const optionTag = `<option value="${Postcode}" ${selectedAttribute}>${Plaatsnaam} (${Postcode})</option>`;

      if (activeRegions.has(`${Hoofdgemeente} + ${Plaatsnaam}`) || generateRegion === false) {
        return optionTag;
      }

      activeRegions.add(`${Hoofdgemeente} + ${Plaatsnaam}`);
      const regionOptionTag = `<option value="regio-${Hoofdgemeente}">${Plaatsnaam} (+ deelgemeentes)</option>`;
      return `${optionTag}\n${regionOptionTag}`;
    }).join('\n');
  };

  const updateSelectInputs = () => {
    const selectInputs = document.querySelectorAll('select[name="zip[]"]');
    selectInputs.forEach((selectInput) => {
      const isInteractiveMapHeader = selectInput.closest('.interactive-map__header') !== null;
      const optionsHtml = generateOptionTags(subcities, !isInteractiveMapHeader);

      selectInput.innerHTML = optionsHtml;
    });
  };

  updateSelectInputs();
}
