const mybutton = document.getElementById('btn-back-to-top');

function scrollFunction() {
  if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
    mybutton.style.display = 'block';
  } else {
    mybutton.style.display = 'none';
  }
}

function backToTop() {
  // $('html').css('scroll-behavior', 'smooth');
  document.querySelector('html').style.scrollBehavior = 'smooth';
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  document.querySelector('html').style.scrollBehavior = 'unset';
  // $('html').css('scroll-behavior', 'unset');
}

export default function () {
  if (mybutton) {
    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
      scrollFunction();
    };

    // When the user clicks on the button, scroll to the top of the document
    mybutton.addEventListener('click', backToTop);
  }
}
