import BasicGoogleMap from '../components/maps/BasicGoogleMap';
import flipGrid from '../components/layout/flipGrid';

export default class PropertyDetail {
  constructor() {
    const map = new BasicGoogleMap();
    map.init();

    if (document.querySelectorAll('.split-text').length > 0) {
      flipGrid();
    }
  }
}
